<template>
  <div>
    <div class="primary">
      <h4 class="mx-8 my-0 py-3 white--text">
        {{ $t("message.billing.paymentOptions") }}
      </h4>
    </div>
    <!-- <v-expansion-panel-header hide-actions class="primary"
      ><h4 class="mx-2 mb-0 white--text">
        Payment Options
      </h4></v-expansion-panel-header
    > -->
    <!-- <v-expansion-panel-content> -->
    <div>
      <v-tabs light slider-color="accent" v-model="active_tab" color="accent">
        <v-tab @click="selectPaymentType('STRIPE')" v-if="isAdmin">{{
          $t("message.billing.creditCard")
        }}</v-tab>
        <v-tab-item v-if="isAdmin">
          <v-card flat class="px-3">
            <v-card-text class="pa-0">
              <v-banner class="accent mb-3 font-weight-black">
                <div class="d-flex justify-center align-center white--text">
                  <v-icon class="ma-4 white--text">mdi-information</v-icon>
                  {{ $t("message.orderViewPage.unavailable") }}
                </div>
              </v-banner>

              <!-- <v-form
                class="form-wrapper pt-6 text-center py-12"
                ref="form"
                v-model="valid"
              >
                <div class="header text-center bg-grey pt-12 pb-4">
                  <div class="mb-6">
                    <img alt="discount" src="/static/images/card.png" />
                  </div>
                  <p
                    v-html="
                      $t('message.paymentPage.creditCardText1', {
                        credit: $formatMoney(
                          userPrivateInfo.credit,
                          2,
                          'USD',
                          1
                        ),
                      })
                    "
                    v-if="
                      userPrivateInfo != null && userPrivateInfo.credit != 0
                    "
                  ></p>
                  <p v-html="$t('message.paymentPage.creditCardText2')"></p>
                </div>
                <div class="pa-4">
                  <v-flex xs12 sm12 md12 lg12 xl12>
                    <v-container>
                      <v-row align="center"
                        ><v-col cols="4"
                          ><div class="text-h6 text-center">
                            {{ $t("message.paymentPage.promoCode") }}
                          </div>
                        </v-col>
                        <v-col cols="8">
                          <v-text-field
                            v-model.trim="coupon"
                            flat
                            :label="$t('message.paymentPage.promoCodeDesc')"
                            class="pa-4"
                          /> </v-col
                      ></v-row>
                    </v-container>
                    <p>{{ $t("message.paymentPage.promoCodeDesc2") }}</p>
                  </v-flex>
                </div>
                <div class="layout row wrap">
                  <v-flex xs12 sm12 md12 lg12 xl12>
                    <v-flex xs12 sm12 md12 lg12 xl12>
                      <div class="text-xl-left text-sm-left">
                        <v-checkbox
                          v-model="acceptTerms"
                          :label="$t('message.orderViewPage.noRefund')"
                        ></v-checkbox>
                      </div>
                    </v-flex>
                    <v-btn
                      class="accent mr-3"
                      @click="makePaymentCard"
                      :loading="!submitBtnEnable"
                      :disabled="
                        getCartTotalPrice(cart_tickets) <= 0 ||
                        !acceptTerms ||
                        !submitBtnEnable
                      "
                      >{{ $t("message.paymentPage.payNowBtn") }}</v-btn
                    >
                  </v-flex>
                </div>
              </v-form> -->
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab @click="selectPaymentType('INTERAC')" color="accent">{{
          $t("message.billing.interac")
        }}</v-tab>
        <!-- <v-tab class="text-capitalize">Credit/Debit/NetBanking</v-tab> -->
        <v-tab-item>
          <v-card flat class="px-3">
            <v-card-text class="pa-0">
              <v-banner class="accent mb-3 font-weight-black">
                <div class="d-flex justify-center align-center white--text">
                  <v-icon class="ma-4 white--text">mdi-information</v-icon>
                  {{ $t("message.orderViewPage.interac_warning") }}
                </div>
              </v-banner>
              <div class="header text-center bg-grey pt-12 pb-4">
                <div class="mb-6">
                  <img alt="discount" src="/static/images/interac.png" />
                </div>
                <p v-html="$t('message.paymentPage.interacText1')"></p>
                <!-- <p
                  v-html="
                    $t('message.paymentPage.interacText1', {
                      amount: $formatMoney(
                        getCartTotalPriceWithCredit(cart_tickets),
                        2,
                        'CAD'
                      ),
                    })
                  "
                ></p>
                <p class="yellow" v-html="$t('message.paymentPage.notice')"></p>
                <p class="font-weight-black text-decoration-underline">
                  {{ $t("message.paymentPage.interacText2") }}
                </p> -->
              </div>
              <v-form
                class="form-wrapper pt-6 text-center py-12"
                ref="form"
                v-model="valid"
              >
                <div class="layout row wrap">
                  <v-flex xs12 sm12 md12 lg12 xl12>
                    <div class="layout row wrap">
                      <v-flex xs12 sm12 md12 lg12 xl12>
                        <div class="d-flex justify-center">
                          <v-checkbox
                            v-model="acceptTerms"
                            :label="$t('message.orderViewPage.noRefund')"
                          ></v-checkbox>
                        </div>
                      </v-flex>
                      <v-flex xs12 sm12 md12 lg12 xl12>
                        <div>
                          <div class="mb-4 font-weight-black">
                            <mark class="pa-3">
                              {{ $t("message.paymentPage.lastStep") }}
                            </mark>
                          </div>
                          <v-btn
                            class="accent mr-3"
                            min-width="200"
                            @click="makePaymentInterac"
                            :disabled="
                              getCartTotalPrice(cart_tickets) <= 0 ||
                              !acceptTerms ||
                              paymentInProcess
                            "
                          >
                            <v-progress-circular
                              indeterminate
                              v-if="paymentInProcess"
                            ></v-progress-circular>
                            <div v-if="!paymentInProcess">
                              {{ $t("message.paymentPage.interacBtn") }}
                            </div>
                          </v-btn>
                        </div>
                      </v-flex>
                    </div>
                  </v-flex>
                </div>
              </v-form>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <!-- <v-tab-item>
						<v-card flat>
							<v-card-text class="pa-0">
								<div class="header text-center bg-grey pt-12 pb-4">
									<div class="mb-6">
										<img alt="Bank" src="/static/images/online-shop.png">
									</div>
									<h4 class="mb-12 font-weight-medium">Select Bank For Net Banking</h4>
								</div>
								<v-divider class="mt-12 mb-2"></v-divider>
								<div class="text-center">
									<v-container grid-list-lg>
										<v-layout row wrap>
											<v-radio-group v-model="currentSelectedBank" :mandatory="false" row text-center>
												<v-radio v-for="bank in netbankingsMerchants" :key="bank.value" :value="bank.value" class="radio-img">
													<template slot="label">
														<img :src="bank.img" width="150" height="30"/>
													</template>
												</v-radio>
											</v-radio-group>
										</v-layout>
										<h6 class="text-left mb-0">All Banks</h6>
										<div class="layout ">
											<v-flex xs9 sm7 md5 lg5 xl5>
												<v-select :items="bankListing" class="mt-0 pt-0"></v-select>
											</v-flex>
										</div>
										<div class="text-left">
											<v-btn class="accent" @click="makePayment">Make Payment</v-btn>
										</div>
									</v-container>
								</div>
							</v-card-text>
						</v-card>
					</v-tab-item> -->
      </v-tabs>
      <v-dialog v-model="interacWarning" max-width="550" persistent>
        <v-card class="py-6 px-2">
          <div class="text-center mb-6 text-h6 text-decoration-underline">
            {{ $t("message.finalReceiptPage.interacWarningMsg") }}
          </div>
          <div class="text-center mb-6 text-h4 font-weight-black">
            {{ $t("message.interacEmail") }}
          </div>
          <div class="text-center my-2">
            <v-btn class="primary" @click="copyToClipboard()">{{ clipboardLabel }}</v-btn>
          </div>
          <div class="yellow accent-2 pa-5">
            <div class="d-flex align-center justify-center font-weight-black">
              {{ $t("message.orderViewPage.important") }}
            </div>
            <div
              class="d-flex align-center justify-center"
              v-if="getUserInfo['email'] != null"
            >
              {{
                $t("message.orderViewPage.transfertNote", {
                  email: getUserInfo["email"],
                })
              }}
            </div>
          </div>
          <v-card-actions class="layout justify-center">
            <v-btn color="accent" @click="interacWarningClose">{{
              $t("message.understand")
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="error_snackbar" color="red" timeout="5000">
        {{ error_message }}

        <template v-slot:action="{ attrs }">
          <v-btn color="white" text @click="error_snackbar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar v-model="stripe_error_snackbar" color="red">
        {{ $t("message.paymentPage.error." + stripe_error_message) }}
        <template v-slot:action="{ attrs }">
          <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="stripe_error_snackbar = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>
    <!-- </v-expansion-panel-content> -->
  </div>
</template>
<style scoped>
</style>
<script>
import { mapGetters } from "vuex";
import { getFunctions, httpsCallable } from "firebase/functions";
import * as coupon_utils from "../../../services/coupon_utils";
export default {
  computed: {
    ...mapGetters([
      "stepOneFormValid",
      "cart",
      "getCartTotalPrice",
      "cart_tickets",
      "cart_info",
      "getUserInfo",
      "getUser",
      "isAdmin",
      "lotteries_info",
      "general_info",
      "coupon_info",
      "utm"
    ]),
  },
  props: ["amount"],
  data() {
    return {
      clipboardLabel: this.$t("message.copyToClipboard"),
      submitBtnEnable: true,
      error_snackbar: false,
      error_message: "UNKNOWN",
      stripe_error_snackbar: false,
      stripe_error_message: "UNKNOWN",
      acceptTerms: false,
      valid: false,
      inputRules: {
        basictextRules: [
          (v) => !!v || this.$t("message.paymentPage.error.empty"),
          (v) =>
            (!!v && v.length >= 3) ||
            this.$t("message.paymentPage.error.length"),
        ],
      },
      val: "",
      currentSelectedBank: "bank-1",
      netbankingsMerchants: [
        {
          value: "bank-1",
          img: "/static/images/topbrands/client-logo-1.png",
        },
        {
          value: "bank-2",
          img: "/static/images/topbrands/client-logo-2.png",
        },
        {
          value: "bank-3",
          img: "/static/images/topbrands/client-logo-3.png",
        },
        {
          value: "bank-4",
          img: "/static/images/topbrands/client-logo-4.png",
        },
        {
          value: "bank-5",
          img: "/static/images/topbrands/client-logo-5	.png",
        },
      ],
      bankListing: ["Option1", "Option2", "Option3", "Option4"],

      lineItems: [
        {
          price: "some-price-id", // The id of the one-time price you created in your Stripe dashboard
          quantity: 1,
        },
      ],
      successURL:
        window.location.origin + this.$route.path + "/checkout/final-receipt",
      cancelURL:
        window.location.origin + this.$route.path + "/checkout/payment",
      publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
      loading: false,
      customerEmail: "",
      clientReferenceId: "",
      userPrivateInfo: null,
      active_tab: 0,
      tabs: [
        { id: 0, name: "STRIPE" },
        { id: 1, name: "INTERAC" },
      ],
      coupon: "",
      interacWarning: false,
      paymentInProcess: false,
    };
  },
  watch: {
    "$store.state.userInfo": function () {
      if (this.getUserInfo != null) {
        this.customerEmail = this.getUserInfo.email;
      }
    },
    "$store.state.userPrivateInfo": function () {
      this.userPrivateInfo = this.$store.state.userPrivateInfo;
    },
  },
  mounted() {
    if (this.getUserInfo != null) {
      this.customerEmail = this.getUserInfo.email;
    }
    this.userPrivateInfo = this.$store.state.userPrivateInfo;

    if (this.isAdmin) {
    var last_payment_type = localStorage.payent_type;
      for (var payment_type of this.tabs) {
        if (payment_type.name == last_payment_type) {
          this.active_tab = payment_type.id;
        }
      }
    }

    this.selectPaymentType(this.active_tab == 0 ? "STRIPE" : "INTERAC");
  },
  methods: {
    /**
     * This Function is to make Payment
     */
    makePaymentInterac() {
      this.interacWarning = true;
    },

    // async prepareInvoice(order, items) {
    //   console.log("Info for functions: ", {
    //     user_info: this.getUserInfo,
    //     order: order,
    //     items: items,
    //   });
    //   var createInvoiceFunc = functions.app
    //     .functions()
    //     .httpsCallable("createInvoice");
    //   createInvoiceFunc({
    //     user_info: this.getUserInfo,
    //     order: order,
    //     items: items,
    //     coupon: this.coupon.trim(),
    //   })
    //     .then((result) => {
    //       console.log("Result data: ", result);
    //       window.location = result.data.url;
    //     })
    //     .catch((error) => {
    //       console.log("ERROR: ", error);
    //       this.stripe_error_message = error.message;
    //       this.submitBtnEnable = true;
    //       this.stripe_error_snackbar = true;
    //     });
    // },
    // async makePaymentCard() {
    //   if (this.getUserInfo.stripe_customer_id == undefined) {
    //     await this.$store.dispatch("fetch_user_info", this.getUser.uid);
    //   }

    //   // this.$store.dispatch("can_buy", { type: this.lottery_name, root: this.$root })
    //   // .then((can_buy) => {
    //   //   this.can_buy = can_buy;
    //   // });

    //   this.submitBtnEnable = false;
    //   this.$store
    //     .dispatch("make_payment_stripe", this.cart_tickets)
    //     .then((db_order) => {
    //       this.lineItems = this.convertCartToStripe(db_order);
    //       this.clientReferenceId = db_order.id;
    //       this.prepareInvoice(db_order, this.lineItems);
    //       // // You will be redirected to Stripe's secure checkout page
    //       // if (this.lineItems.length != 0) {
    //       //   this.$refs.checkoutRef.redirectToCheckout();
    //       // } else {
    //       //   console.log("Empty item.");
    //       // }
    //     })
    //     .catch((error) => {
    //       this.stripe_error_message = error.message;
    //       this.submitBtnEnable = true;
    //       this.stripe_error_snackbar = true;
    //       console.log("Error: ", error);
    //     });
    // },
    selectPaymentType(type) {
      localStorage.payent_type = type;
      if (type == "INTERAC") {
        this.$store.commit("set_selected_payment_type_currency", "CAD");
      } else {
        this.$store.commit("set_selected_payment_type_currency", "USD");
      }
    },
    interacWarningClose() {
      this.paymentInProcess = true;
      this.interacWarning = false;
      this.submitBtnEnable = false;
      this.$refs.form.validate();

      if (
        this.cart_tickets.gift_name != undefined &&
        (this.cart_tickets.gift_name.trim() == "" ||
          this.cart_tickets.gift_email.trim() == "" ||
          !this.cart_tickets.gift_email.includes("@"))
      ) {
        this.error_message = "Invalid gift receipient information"; //this.$t("message.paymentPage.error.UNKNOWN");
        this.error_snackbar = true;
        this.paymentInProcess = false;
        return;
      }
      if (this.valid) {
        var coupon = "";
        if (this.coupon_info != null) {
          coupon = this.coupon_info.name;
        }
        const interacPaymentFunc = httpsCallable(
          getFunctions(),
          "createInteracPayment"
        );
        
        interacPaymentFunc({
          user_info: this.getUserInfo,
          cart: this.cart_tickets,
          cart_info: this.cart_info,
          use_credit: true,
          currency_rate: this.general_info.currency["CAD"],
          coupon: coupon,
        })
          .then((result) => {
            if (result.data.code == "OK") {
              this.$store.commit("setCouponInfo", null);
              this.$store.commit("setCartInfo", {});
              this.$router.replace(
                "/checkout/final-receipt/" + result.data.order_id
              );
              var items = [];
              var index = 0;
              for (var current_ticket of this.cart_tickets){
                var ticket_type = coupon_utils.convert_ticket_to_code(current_ticket.ticket);
                var item_content = {
                  item_id: ticket_type,
                  index: index,
                  coupon:coupon,
                }
                items.push(item_content);
                index++;
              }  
              var local_utm = this.utm;
              if (local_utm == null) {
                local_utm = {
                  utm_source: "direct",
                  utm_medium: "direct",
                  utm_campaign: "direct",
                };
              }
              var purchase_info = {
                transaction_id: result.data.order_id,
                value: this.getCartTotalPrice(this.cart_tickets, this.coupon_info),
                currency: "CAD",
                items: items,
                coupon: coupon,
                utm_source: local_utm.utm_source,
                utm_medium: local_utm.utm_medium,
                utm_campaign: local_utm.utm_campaign,
              };
              this.$gtag.purchase(purchase_info);
            } else {
              var error = result.data;
              this.paymentInProcess = false;
              if ("code" in error) {
                this.error_message = this.$t(
                  "message.paymentPage.error." + error.error_type,
                  { type_name: this.$t("message." + error.type_name) }
                );
              } else {
                this.error_message = this.$t(
                  "message.paymentPage.error.UNKNOWN"
                );
              }
              this.error_snackbar = true;
            }
          })
          .catch((error) => {
            console.log("ERROR: ", error);
            this.error_message = this.$t("message.paymentPage.error.UNKNOWN");
            this.error_snackbar = true;
            this.paymentInProcess = false;
          });
      }
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.$t("message.interacEmail"));
      this.clipboardLabel = this.$t("message.copied");
    }

  },
};
</script>