<template>
  <div>
    <div v-if="userPrivateInfo">
      <div class="primary">
        <h4 class="mx-8 my-0 py-3 white--text">
          {{ $t("message.orderViewPage.orders") }}
        </h4>
      </div>
      <div class="cart-content mt-2">
        <v-container grid-list-xl pa-0>
          <div class="cart-shop-list">
            <div class="px-16">
              <div v-for="(item, index) in userCart" :key="index">
                <v-row class="cart-item-wrap pb-2">
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    class="d-inline-flex align-center justify-left ma-0 pa-0"
                  >
                    <div class="text-left mt-2">
                      <h6 class="mb-0 justify-start align-start">
                        {{
                          lotteries_info.lotteries[item.ticket.type_name].name +
                          " " +
                          ticketShortDescription(item.ticket)
                        }}
                      </h6>
                      <p>{{ ticketShortSubDescription(item.ticket) }}</p>
                      <span class="mb-0" v-if="!isQuickPicks(item.ticket)">
                        <div
                          class="mx-2"
                          v-for="(
                            numbers_item, numbers_index
                          ) in getNumbersList(item.ticket)"
                          :key="numbers_index"
                        >
                          {{ numbers_item }}
                        </div>
                      </span>
                    </div>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="3"
                    md="2"
                    class="d-inline-flex align-center justify-left ma-0 pa-0"
                  >
                    <div v-if="isQuickPicks(item.ticket)">
                      <v-text-field
                        class="d-inline-block"
                        type="number"
                        v-model="item.ticket.quickPicks"
                        min="3"
                        max="60"
                        step="3"
                        placeholder="Quantity"
                        @click="validateQuickPicks(item.ticket)"
                        @change="validateQuickPicks(item.ticket)"
                      >
                      </v-text-field>
                    </div>
                    <!-- <div v-else-if="item.ticket.group != undefined">
                      <div class="d-flex flex-row align-center">
                        <div>
                          <v-text-field
                            type="number"
                            v-model="item.ticket.group_parts"
                            min="1"
                            :max="getGroupMax(item.ticket.group)"
                            placeholder="Quantity"
                            @click="validateGroup(item.ticket)"
                            @change="validateGroup(item.ticket)"
                          >
                          </v-text-field>
                        </div>
                        <div>{{ $t("message.parts") }}</div>
                      </div>
                    </div> -->
                    <div v-else>
                      <v-btn
                        small
                        outlined
                        color="primary"
                        @click="editGrids(index)"
                      >
                        {{ $t("message.orderViewPage.edit") }}
                        <v-icon> mdi-pencil </v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="3"
                    md="2"
                    class="d-inline-flex align-center justify-center ma-0 pa-0"
                  >
                  <div v-if="!is_discounted(item)">
                    <h6 class="mb-0">
                      {{
                        $formatMoney(
                          getTicketSubTotalPrice({
                            ticket: item.ticket,
                            with_discount: true,
                          }),
                          2,
                          currency
                        )
                      }}
                    </h6>
                  </div>
                    <div v-else>
                    <h6 class="mb-0 text-decoration-line-through red--text" >
                      {{
                        $formatMoney(
                          getTicketSubTotalPrice({
                            ticket: item.ticket,
                            with_discount: true,
                          }),
                          2,
                          currency
                        )
                      }}
                    </h6>
                    <h6 class="mb-0 px-4">
                      <!-- {{
                        $formatMoney(
                          convert_currency(coupon_info.currency, "USD", coupon_info.price)
                          ,
                          2,
                          currency
                        )
                      }} -->
                      {{
                        $formatMoney(
                          getTicketSubTotalPrice({
                            ticket: item.ticket,
                            with_discount: true,
                            coupon: coupon_info
                          }),
                          2,
                          currency
                        )
                      }}
                    </h6>
                  </div>
                  </v-col>
                  <v-col
                    cols="2"
                    sm="2"
                    md="2"
                    class="
                      res-float-icon
                      d-inline-flex
                      align-center
                      justify-md-center justify-end
                      ma-0
                      pa-0
                      mt-2
                    "
                  >
                    <a
                      class="accent--text remove-cart"
                      @click="deleteProductFromCart(index)"
                      href="javascript:void(0)"
                    >
                      <i class="material-icons font-weight-bold">close </i>
                    </a>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
        </v-container>

        <v-layout align-center justify-end class="px-12 pt-12">
          <v-flex xs12 sm12 md5 lg5 xl5 px-12>
            <!-- <div class="layout align-center justify-space-between subtotal">
              <p>{{ $t("message.billing.subtotal") }}</p>
              <span>
                {{ $formatMoney(getCartSubTotalPrice(userCart), 2, currency) }}
              </span>
            </div> -->

            <div class="layout align-center justify-space-between subtotal">
              <p>
                <!-- {{ $t("message.billing.coupon") }} -->
              </p>
              <span>
                <div class="d-flex justify-end align-center py-3">
                  <div>
                    <v-text-field
                      :label="$t('message.billing.enterCoupon')"
                      v-model="coupon"
                      :disabled="coupon_info != null || coupon_processing"
                    ></v-text-field>
                  </div>
                  <div class="pl-3" v-if="coupon_info == null">
                    <v-btn small @click="applyCoupon()">
                      <div v-if="!coupon_processing">
                        {{ $t("message.billing.apply") }}
                      </div>
                      <div v-else>
                        <v-progress-circular
                          indeterminate
                          size="16"
                        ></v-progress-circular>
                      </div>
                    </v-btn>
                  </div>
                  <div class="pl-3" v-else>
                    <v-btn small icon @click="removeCoupon()">
                      <v-icon>mdi-close</v-icon></v-btn
                    >
                  </div>
                </div>

              </span>
            </div>
            <div class="d-flex justify-center align-center pb-4" v-if="coupon_info != null && !coupon_applicable() && coupon_info.info != undefined">
              <div class="pa-2 yellow lighten-4">
                {{  coupon_info.info[selectedLocale.locale] }}
            </div>
              </div>
            <div
              class="layout align-center justify-space-between subtotal mb-6"
              v-if="getCredit() > 0"
            >
              <p>
                {{ $t("message.billing.credit") }}
              </p>
              <span>
                {{ "-" + $formatMoney(getCredit(), 2, currency) }}
              </span>
            </div>
            <!-- <v-divider class="my-3"></v-divider> -->
            <div
              class="
                layout
                align-center
                justify-space-between
                subtotal
                layout-gap
              "
            >
              <h4>{{ $t("message.billing.total") }}</h4>
              <h4>
                {{
                  $formatMoney(
                    getCartTotalPriceWithCredit(userCart, coupon_info),
                    2,
                    currency
                  )
                }}
              </h4>
            </div>
          </v-flex>
        </v-layout>
      </div>
      <div>
        <!-- <v-container grid-list-xl> -->
        <div class="cart-shop-list grey lighten-4">
          <div class="px-16 pt-3">
            <h6>{{ $t("message.billing.ticket_destination.title") }}</h6>
          </div>
          <div class="px-16 d-flex justify-center">
            <v-radio-group
              v-model="destination_type"
              @change="giftOrGroupChange()"
              dense
            >
              <v-radio
                :label="$t('message.billing.ticket_destination.forYou')"
                value="normal"
                color="accent"
              ></v-radio>
              <v-radio
                :label="$t('message.billing.ticket_destination.gift')"
                value="gift"
                color="accent"
              ></v-radio>
              <v-radio
                :label="$t('message.billing.ticket_destination.group')"
                value="group"
                color="accent"
              ></v-radio>
            </v-radio-group>
          </div>
        </div>
        <div
          class="px-16 pt-4 grey lighten-4"
          v-if="destination_type == 'gift'"
        >
          <div class="d-flex">
            <div v-html="$t('message.orderViewPage.giftDesc')"></div>
            <div class="ma-2">
              <v-img src="/static/images/gift.png" width="50"></v-img>
            </div>
          </div>
          <v-text-field
            :label="$t('message.orderViewPage.giftName')"
            v-model="gift_name"
            @change="giftOrGroupChange()"
          ></v-text-field>
          <v-text-field
            :label="$t('message.orderViewPage.giftEmail')"
            type="email"
            v-model="gift_email"
            @change="giftOrGroupChange()"
          ></v-text-field>
          <v-textarea
            v-model="gift_note"
            :label="$t('message.orderViewPage.giftNote')"
            shaped
            @change="giftOrGroupChange()"
          >
          </v-textarea>
        </div>
        <div
          class="px-16 pt-2 grey lighten-4"
          v-if="destination_type == 'group'"
        >
          <div class="d-flex">
            <div v-html="$t('message.orderViewPage.groupDesc')"></div>
            <div class="ma-2">
              <v-img src="/static/images/group.png" width="50"></v-img>
            </div>
          </div>
          <v-text-field
            :label="$t('message.orderViewPage.groupName')"
            v-model="group_name"
            @change="giftOrGroupChange()"
            @keyup="groupKeyUp()"

          ></v-text-field>
        </div>
        <!-- </v-container> -->
      </div>
      <emb-delete-confirmation
        ref="deleteConfirmationDialog"
        :message="$t('message.orderViewPage.deleteProduct')"
        @onConfirm="onDeleteProductFromCart"
      >
      </emb-delete-confirmation>
    </div>
    <v-snackbar v-model="snackbar" color="red">
      {{ $t("message.orderViewPage.invalidCoupon") }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>



<script>
import { mapGetters } from "vuex";
import { getFunctions, httpsCallable } from "firebase/functions";
import * as coupon_utils from "../../../services/coupon_utils";

export default {
  computed: {
    ...mapGetters([
      "cart_tickets",
      "cart_info",
      "lotteries_info",
      "getCartSubTotalPrice",
      "getCartTotalPrice",
      "getCartTotalPriceWithCredit",
      "getTicketSubTotalPrice",
      "getUserPrivateInfo",
      "selected_payment_type_currency",
      "general_info",
      "coupon_info",
      "selectedLocale",
    ]),
  },
  props: ["changeStepOneForm"],
  data() {
    return {
      userCart: [],
      userCartInfo: {},
      userPrivateInfo: null,
      productIndexToDelete: -1,
      valid: false,
      currency: "USD",
      coupon: "",
      coupon_processing: false,
      snackbar: false,
      gift: false,
      gift_name: "",
      gift_email: "",
      gift_note: "",
      group_name: "",
      destination_type: "normal", // normal, gift or group
      userInfo: {
        firstName: "",
        lastName: "",
        streetName: "",
        aptBuilding: "",
        zipCode: "",
        cityState: "",
        country: "",
        phone: "",
      },
      inputRules: {
        basictextRules: [(v) => !!v || "This field should not be empty."],
        emailRules: [
          (v) => !!v || "This field should not be empty.",
          (v) =>
            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
            "E-mail must be valid",
        ],
      },
    };
  },
  watch: {
    "$store.state.cart_tickets": function () {
      this.userCart = this.$store.state.cart_tickets;
    },
    "$store.state.cart_info": function () {
      this.userCartInfo = this.$store.state.cart_info;
    },
    "$store.state.userPrivateInfo": function () {
      this.userPrivateInfo = this.$store.state.userPrivateInfo;
    },
    "$store.state.selected_payment_type_currency": function () {
      this.currency = this.$store.state.selected_payment_type_currency;
    },
  },
  mounted() {
    this.userCart = this.$store.state.cart_tickets;
    this.userCartInfo = this.$store.state.cart_info;
    this.userPrivateInfo = this.$store.state.userPrivateInfo;
    this.currency = this.$store.state.selected_payment_type_currency;
    if (this.coupon_info != null) {
      this.coupon = this.coupon_info.name;
    }
  },
  methods: {
    ticketShortDescription(ticket) {
      if (ticket.quickPicks != undefined && ticket.quickPicks > 0) {
        return "(" + " Quickpicks)";
      } else {
        return "(" + " " + this.$t("message.productsPage.grids") + ")";
      }
    },
    ticketShortSubDescription(ticket) {
      var description = "";
      if (ticket.multiplier) {
        description = this.$t(
          "message.productsPage.multiplier." + ticket.type_name + ".name"
        );
      }
      if (ticket.multiplay > 1) {
        if (description != "") {
          description += ", ";
        }
        description +=
          ticket.multiplay.toString() +
          " " +
          this.$t("message.productsPage.multiplayDraws");
      }
      if (description == "") {
        return "";
      } else {
        return "(" + description + ")";
      }
    },
    isQuickPicks(ticket) {
      return ticket.quickPicks != undefined && ticket.quickPicks > 0;
    },
    deleteProductFromCart(index) {
      this.$refs.deleteConfirmationDialog.openDialog();
      this.productIndexToDelete = index;
    },
    onDeleteProductFromCart() {
      this.$refs.deleteConfirmationDialog.close();
      this.$snotify.success(this.$t("message.orderViewPage.ticketRemoved"), {
        closeOnClick: false,
        pauseOnHover: false,
        timeout: 1000,
      });
      this.$store.dispatch("remove_from_cart", this.productIndexToDelete);
    },
    getNumbersList(ticket) {
      var result = [];
      for (var grid of ticket.grids) {
        var grid_numbers = "";
        for (var pool of grid.pool) {
          var pool_numbers = "";
          for (var number of pool.numbers) {
            if (pool_numbers != "") {
              pool_numbers += "-";
            }
            if (number < 10) {
              pool_numbers += "0";
            }
            pool_numbers += number.toString();
          }
          if (grid_numbers != "") {
            grid_numbers += " ";
          }
          grid_numbers += pool_numbers;
        }
        result.push(grid_numbers);
      }
      return result;
    },
    editGrids(index) {
      this.$router.push("/cart/" + index.toString());
    },
    getCredit() {
      var total = this.getCartTotalPrice(this.userCart);
      var credit = this.userPrivateInfo.credit;
      return credit >= total ? total : credit;
    },
    validateQuickPicks(ticket) {
      if (typeof ticket.quickPicks == "string") {
        ticket.quickPicks = parseInt(ticket.quickPicks);
      }

      if (ticket.quickPicks < 3) {
        ticket.quickPicks = 3;
      } else if (ticket.quickPicks > 60) {
        ticket.quickPicks = 60;
      }

      if (ticket.quickPicks % 3 != 0) {
        ticket.quickPicks = ticket.quickPicks - (ticket.quickPicks % 3);
      }
    },
    applyCoupon() {
      this.coupon = this.coupon.trim();
      if (this.coupon != "") {
        this.coupon_processing = true;
        const validateCouponFunc = httpsCallable(
          getFunctions(),
          "validateCoupon"
        );
        validateCouponFunc({ coupon: this.coupon })
          .then((result) => {
            this.coupon_processing = false;
            if (result.data.code == "OK") {
              // this.coupon_desc = result.data.coupon;
              this.userCart.coupon = result.data.coupon;
              this.$store.commit("setCouponInfo", result.data.coupon.info);
            } else {
              console.log("Invalid coupon");
              this.snackbar = true;
            }
          })
          .catch((error) => {
            this.coupon_processing = false;
            console.error("Error: ", error);
            this.snackbar = true;
          });
      }
    },
    removeCoupon() {
      this.$store.commit("setCouponInfo", null);
      this.coupon = "";
      delete this.userCart.coupon;
    },
    giftOrGroupChange() {
      // if (this.group_name.length > 10) {
      //   console.log("hey too long!");
      //   this.group_name = this.group_name.substring(0,10);
      // }
      if (this.destination_type == "gift") {
        this.userCartInfo.gift_name = this.gift_name;
        this.userCartInfo.gift_email = this.gift_email;
        this.userCartInfo.gift_note = this.gift_note;
      } else if (this.destination_type == "group") {
        this.userCartInfo.group_name = this.group_name;
      } else {
        delete this.userCartInfo["gift_name"];
        delete this.userCartInfo["gift_email"];
        delete this.userCartInfo["gift_note"];
        delete this.userCartInfo["group_note"];
      }
    },
    groupKeyUp() {
      if (this.group_name.length > 30) {
        this.group_name = this.group_name.substring(0,30);
      }
    },
    is_discounted(item) {
      if (this.coupon_info != null) {
        const product_code = coupon_utils.convert_ticket_to_code(item.ticket);
        for (var pattern of this.coupon_info.products) {
          if (coupon_utils.compare_product_pattern(pattern, product_code)[0]) {
            return true;
          }
        }
      }
      return false;
    },
    convert_currency(from, to, price) {
      return coupon_utils.convert_currency(this.general_info, from, to, price);
    },
    // Check if the coupon is applicable to the cart
    coupon_applicable() {
      for (var item of this.userCart) {
        if (this.is_discounted(item)) {
          return true;
        }
      }
      return false;
    }
  },
};
</script>